import React, {useEffect, useState,useId} from 'react';
import './Admin.css';
import {addDoc, collection, doc, getDocs, updateDoc, deleteDoc, getDoc} from "firebase/firestore/lite";
import db from "../../firebase";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import {
    Container,
    Card,
    CardMedia,
    CardContent,
    Typography,
    Stack,
    TextField,
    Box,
    Grid,
    Checkbox, FormControlLabel
} from "@mui/material";
import Button from "@mui/material/Button";
import {updateDocument} from "../../services/utils";
import {Link} from "react-router-dom";
import { v4 as uuid } from "uuid";
import {auth} from "../../firebase";

function AdminPage(props) {
    const [date, setDate] = useState('');
    const [location, setLocation] = useState('');
    const [manAmount, setManAmount] = useState('');
    const [manAmountBought, setManAmountBought] = useState(0);
    const [womanAmount, setWomanAmount] = useState('');
    const [womanAmountBought, setWomanAmountBought] = useState(0);
    const [showDiscount, setShowDiscount] = useState(false);
    const [priceWithDiscount, setPriceWithDiscount] = useState('');
    const [time, setTime] = useState('');
    const [animator, setAnimator] = useState('');
    const [price, setPrice] = useState('');
    const [currency, setCurrency] = useState('');
    const [age, setAge] = useState('');
    const [adress, setAdress] = useState('');
    const [name, setName] = useState('');
    const [imgLink, setImgLink] = useState('https://img.freepik.com/free-photo/colorful-heart-air-balloon-shape-collection-concept-isolated-color-background-beautiful-heart-ball-event_90220-1047.jpg');
    const [showToUser, setShowToUser] = useState(false);
    const [description, setDescription] = useState('');
    const [showEditCardModal,setShowEditCardModal] = useState(false);
    const [allDates, setAllDates] = useState([]);
    const [adminEmail, setAdminEmail] = useState('');
    const [adminPass, setAdminPass] = useState('');
    const [isAdminLoggedIn, setIsAdminLoggedIn] = useState(false);

    const [currentEditingId, setCurrentEditingId] = useState('');

    const [editedFields, setEditedFields] = useState({
        location: '',
        manAmount: '',
        manAmountBought: '',
        womanAmount: '',
        womanAmountBought: '',
        time: '',
        description: '',
        price: '',
        users: [],
        showToUser: false,
        name: '',
        imgLink: '',
        age: '',
        adress: '',
        showDiscount: false,
        priceWithDiscount: '',
        currency: '',
        animator: '',
    });

    const handleEditChange = (index, field, value, id) => {
        console.log('idididid',id)
        console.log('editedFields',editedFields);
        setEditedFields(prevState => ({
                ...prevState,
                [field]: value
        }));

        console.log('[index]', {
                [field]: value
        })
    };
    async function addDateToDb(date) {
        try {
            const docRef = await addDoc(collection(db, "dates"), date);
            console.log("Document written with ID: ", docRef.id);
            const dateDoc = await getDoc(doc(db, "dates", docRef.id)); // Fetch the date document based on dateId
            if (dateDoc.exists()) {
                // New unique id
                const unique_id = uuid();
                // Get first 8 characters using slice
                const small_id = unique_id.slice(0, 8);
                await updateDocument(docRef.id, {...dateDoc.data(), id: docRef.id, uniqueDateId: small_id});
                console.log('{...dateDoc.data(), id: docRef.id}',{...dateDoc.data(), id: docRef.id, uniqueDateId: small_id})
            } else {
                console.log("No such document!");
            }
        } catch (error) {
            console.error("Error adding document: ", error);
        }

        // Fetch all documents from the "dates" collection
        const querySnapshot = await getDocs(collection(db, "dates"));
        const dates = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setAllDates(dates);
        console.log('dates', dates);
        return dates; // Return all documents
    }

    const editDate = async (updatedDate) => {
        await updateDocument(currentEditingId, updatedDate);
    };
    const handleAddDate = async () => {
        const dateInfo = {
            date,
            location,
            manAmount,
            manAmountBought,
            womanAmount,
            womanAmountBought,
            time,
            description,
            price,
            users: [],
            showToUser,
            name,
            imgLink,
            dateCreated: new Date(),
            age,
            adress,
            showDiscount,
            priceWithDiscount,
            currency,
            animator
        };

        try {
            const newDateId = await addDateToDb(dateInfo);
            const updatedDates = [...allDates, { id: newDateId, ...dateInfo }];
            setAllDates(updatedDates);
        } catch (error) {
            console.error("Error adding date:", error);
        }
    };

    const deleteDate = async id => {
        try {
            await deleteDoc(doc(db, "dates", id));
            console.log("Document deleted with ID: ", id);
            const updatedDates = allDates.filter(date => date.id !== id);
            setAllDates(updatedDates);
        } catch (error) {
            console.error("Error deleting document: ", error);
        }
    };

    const handleEdit = async (id) => {
        console.log('id', id);
        console.log('editedFields', editedFields);

        try {
            console.log('for edit edited id', id)
            await editDate(editedFields);
            console.log('allDates',allDates)
            const updatedDates = allDates.map(date => {
                console.log('date id', date.id)
                console.log('id', id)
                if (date.id === currentEditingId) {
                    return editedFields;
                } else {
                    return date;
                }
            });
            console.log('updatedDates',updatedDates)
            setAllDates(updatedDates);
            setEditedFields({
                location: '',
                manAmountBought: '',
                manAmount: '',
                womanAmount: '',
                womanAmountBought: '',
                time: '',
                description: '',
                price: '',
                users: [],
                showToUser: false,
                name: '',
                imgLink: '',
                age: '',
                adress: '',
                showDiscount: '',
                priceWithDiscount: '',
                currency: '',
            }); // Clear edited fields
        } catch (error) {
            console.error("Error editing date:", error);
        }
    };
    const handleLogin = () => {
        signInWithEmailAndPassword(auth, adminEmail, adminPass)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                setIsAdminLoggedIn(true);
                localStorage.setItem('admin', 'true');
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                setIsAdminLoggedIn(false);
            });
    }
    const logout = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
            console.log('success logo');
            localStorage.removeItem('admin')
            setIsAdminLoggedIn(false);

        }).catch((error) => {
            // An error happened.
            console.log('error logo', error)

        });
    }
     const handleOpenModalEdit = async (dateId) => {
        console.log('date ID on open midal',dateId)
        setCurrentEditingId(dateId);
        setShowEditCardModal(true);
        try {
            const dateDoc = await getDoc(doc(db, "dates", dateId)); // Fetch the date document based on dateId
            if (dateDoc.exists()) {
                console.log('{ id: dateDoc.id, ...dateDoc.data() }',{ id: dateId, ...dateDoc.data() })
                // setDateInfo({ id: dateDoc.id, ...dateDoc.data() }); // Set the date info state
                setEditedFields({
                    id: dateDoc.data().id,
                    location: dateDoc.data().location,
                    manAmount: dateDoc.data().manAmount,
                    manAmountBought: dateDoc.data().manAmountBought,
                    womanAmount: dateDoc.data().womanAmount,
                    womanAmountBought: dateDoc.data().womanAmountBought,
                    time: dateDoc.data().time,
                    description: dateDoc.data().description,
                    price: dateDoc.data().price,
                    currency: dateDoc.data().currency,
                    showToUser: dateDoc.data().showToUser,
                    name: dateDoc.data().name,
                    users: dateDoc.data().users,
                    imgLink: dateDoc.data().imgLink,
                    age: dateDoc.data().age,
                    adress: dateDoc.data().adress,
                    showDiscount: dateDoc.data().showDiscount,
                    priceWithDiscount: dateDoc.data().priceWithDiscount,
                    animator: dateDoc.data().animator,
                })
            } else {
                console.log("No such document!");
            }
        } catch (error) {
            console.error("Error fetching date info:", error);
        }

    }
    useEffect(() => {
        const fetchDates = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "dates"));
                const dates = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setAllDates(dates);
            } catch (error) {
                console.error("Error fetching dates:", error);
            }
        };

        fetchDates();
    }, []);
    return (
        <div>
            {
                localStorage.getItem('admin') === 'true' || isAdminLoggedIn ? (
                <>
                    <div>
                        <button onClick={logout}>logout</button>
                        <div>
                            <h2 className='adminTitle'>
                                Add Date
                            </h2>
                            <div className='addDateInputs'>
                                <div className='addDateInputsLeft'>
                                    <div >
                                        <TextField
                                            label="Name"
                                            type="text"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            fullWidth
                                            size='small'
                                        />
                                    </div>
                                    <div >
                                        <TextField
                                            label="Img link"
                                            type="text"
                                            value={imgLink}
                                            onChange={(e) => setImgLink(e.target.value)}
                                            fullWidth
                                            size='small'
                                        />
                                    </div>
                                    <div>
                                        <TextField
                                            type="date"
                                            value={date}
                                            onChange={(e) => setDate(e.target.value)}
                                            fullWidth
                                            size='small'
                                        />
                                    </div>
                                    <div>
                                        <TextField
                                            label="Location"
                                            type="text"
                                            value={location}
                                            onChange={(e) => setLocation(e.target.value)}
                                            fullWidth
                                            size='small'
                                        />
                                    </div>
                                    <div>
                                        <TextField
                                            label="Adress"
                                            type="text"
                                            value={adress}
                                            onChange={(e) => setAdress(e.target.value)}
                                            fullWidth
                                            size='small'
                                        />
                                    </div>
                                    <div>
                                        <TextField
                                            label="Man Amount"
                                            type="text"
                                            value={manAmount}
                                            onChange={(e) => setManAmount(e.target.value)}
                                            fullWidth
                                            size='small'
                                        />
                                    </div>
                                    <div>
                                        <TextField
                                            label="Man Amount Bought"
                                            type="text"
                                            value={manAmountBought}
                                            onChange={(e) => setManAmountBought(e.target.value)}
                                            fullWidth
                                            size='small'
                                        />
                                    </div>
                                    <div>
                                        <TextField
                                            label="Woman Amount"
                                            type="text"
                                            value={womanAmount}
                                            onChange={(e) => setWomanAmount(e.target.value)}
                                            fullWidth
                                            size='small'
                                        />
                                    </div>
                                    <div>
                                        <TextField
                                            label="Woman Amount Bought"
                                            type="text"
                                            value={womanAmountBought}
                                            onChange={(e) => setWomanAmountBought(e.target.value)}
                                            fullWidth
                                            size='small'
                                        />
                                    </div>
                                </div>
                                <div className='addDateInputsRight'>
                                    <div>
                                        <TextField
                                            label="Age"
                                            type="text"
                                            value={age}
                                            onChange={(e) => setAge(e.target.value)}
                                            fullWidth
                                            size='small'
                                        />
                                    </div>
                                    <div>
                                        <TextField
                                            label="Time"
                                            type="text"
                                            value={time}
                                            onChange={(e) => setTime(e.target.value)}
                                            fullWidth
                                            size='small'
                                        />
                                    </div>
                                    <div>
                                        <TextField
                                            label="Animator"
                                            type="text"
                                            value={animator}
                                            onChange={(e) => setAnimator(e.target.value)}
                                            fullWidth
                                            size='small'
                                        />
                                    </div>
                                    <div>
                                        <TextField
                                            label="Price"
                                            type="text"
                                            value={price}
                                            onChange={(e) => setPrice(e.target.value)}
                                            fullWidth
                                            size='small'
                                        />
                                    </div>
                                    <div>
                                        <TextField
                                            label="Currency"
                                            type="text"
                                            value={currency}
                                            onChange={(e) => setCurrency(e.target.value)}
                                            fullWidth
                                            size='small'
                                        />
                                    </div>
                                    <div>
                                        <TextField
                                            label="Description"
                                            type="text"
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            fullWidth
                                            // size='small'
                                            multiline
                                            rows={null}
                                        />
                                    </div>
                                    <div>
                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={showDiscount}
                                                onChange={() => setShowDiscount(!showDiscount)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />}
                                            label="Show Discount"
                                        />
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Price with discount"
                                                type="text"
                                                value={priceWithDiscount}
                                                onChange={(e) => setPriceWithDiscount(e.target.value)}
                                                fullWidth
                                                size='small'
                                            />
                                        </Grid>
                                    </div>
                                    <div>
                                        <FormControlLabel
                                            control={<Checkbox
                                                checked={showToUser}
                                                onChange={() => setShowToUser(!showToUser)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />}
                                            label="Show To User"
                                        />
                                    </div>
                                    <div>
                                        <Button variant="contained" color="primary" onClick={handleAddDate}>
                                            Add Date
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='adminCards'>
                        {allDates.map((date,index) => (
                            <div  className='adminCardWrapper'>
                                {
                                    showEditCardModal && (
                                        <div className='cardEdit'>
                                            <button onClick={() => setShowEditCardModal(false)} className='buttonCloseEdit'>close</button>
                                            <Stack spacing={2} p={2}>
                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                    <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                                        Name:
                                                    </Typography>
                                                    <TextField
                                                        type="text"
                                                        value={editedFields.name}
                                                        onChange={(e) => handleEditChange(index, 'name', e.target.value)}
                                                        size="small"
                                                        style = {{width: '100%'}}
                                                    />
                                                </Stack>
                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                    <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                                        Img Link:
                                                    </Typography>
                                                    <TextField
                                                        type="text"
                                                        value={editedFields.imgLink}
                                                        onChange={(e) => handleEditChange(index, 'imgLink', e.target.value)}
                                                        size="small"
                                                        style = {{width: '100%'}}
                                                    />
                                                </Stack>
                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                    <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                                        Date:
                                                    </Typography>
                                                    <TextField
                                                        type="date"
                                                        value={editedFields.date}
                                                        onChange={(e) => handleEditChange(index, 'date', e.target.value)}
                                                        size="small"
                                                        style = {{width: '100%'}}
                                                    />
                                                </Stack>
                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                    <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                                        Location:
                                                    </Typography>
                                                    <TextField
                                                        type="text"
                                                        value={editedFields.location}
                                                        onChange={(e) => {
                                                            // const newValue = e.target.value.trim() !== '' ? e.target.value : '';
                                                            handleEditChange(index, 'location', e.target.value);
                                                        }}
                                                        size="small"
                                                        style = {{width: '100%'}}
                                                    />
                                                </Stack>
                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                    <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                                        Adress:
                                                    </Typography>
                                                    <TextField
                                                        type="text"
                                                        value={editedFields.adress}
                                                        onChange={(e) => {
                                                            // const newValue = e.target.value.trim() !== '' ? e.target.value : '';
                                                            handleEditChange(index, 'adress', e.target.value, date.id);
                                                        }}
                                                        size="small"
                                                        style = {{width: '100%'}}
                                                    />
                                                </Stack>
                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                    <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                                        Man Amount:
                                                    </Typography>
                                                    <TextField
                                                        type="text"
                                                        value={editedFields.manAmount}
                                                        onChange={(e) => handleEditChange(index, 'manAmount', e.target.value)}
                                                        size="small"
                                                        style = {{width: '100%'}}
                                                    />
                                                </Stack>
                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                    <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                                        Man Amount Bought:
                                                    </Typography>
                                                    <TextField
                                                        type="text"
                                                        value={editedFields.manAmountBought}
                                                        onChange={(e) => handleEditChange(index, 'manAmountBought', e.target.value)}
                                                        size="small"
                                                        style = {{width: '100%'}}
                                                    />
                                                </Stack>
                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                    <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                                        Woman Amount:
                                                    </Typography>
                                                    <TextField
                                                        type="text"
                                                        value={editedFields.womanAmount}
                                                        onChange={(e) => handleEditChange(index, 'womanAmount', e.target.value)}
                                                        size="small"
                                                        style = {{width: '100%'}}
                                                    />
                                                </Stack>
                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                    <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                                        Woman Amount Bought:
                                                    </Typography>
                                                    <TextField
                                                        type="text"
                                                        value={editedFields.womanAmountBought}
                                                        onChange={(e) => handleEditChange(index, 'womanAmountBought', e.target.value)}
                                                        size="small"
                                                        style = {{width: '100%'}}
                                                    />
                                                </Stack>
                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                    <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                                        Age:
                                                    </Typography>
                                                    <TextField
                                                        type="text"
                                                        value={editedFields.age}
                                                        onChange={(e) => handleEditChange(index, 'age', e.target.value)}
                                                        size="small"
                                                        style = {{width: '100%'}}
                                                    />
                                                </Stack>
                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                    <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                                        Time:
                                                    </Typography>
                                                    <TextField
                                                        type="text"
                                                        value={editedFields.time}
                                                        onChange={(e) => handleEditChange(index, 'time', e.target.value)}
                                                        size="small"
                                                        style = {{width: '100%'}}
                                                    />
                                                </Stack>
                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                    <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                                        Animator:
                                                    </Typography>
                                                    <TextField
                                                        type="text"
                                                        value={editedFields.animator}
                                                        onChange={(e) => handleEditChange(index, 'animator', e.target.value)}
                                                        size="small"
                                                        style = {{width: '100%'}}
                                                    />
                                                </Stack>
                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                    <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                                        Price:
                                                    </Typography>
                                                    <TextField
                                                        type="text"
                                                        value={editedFields.price}
                                                        onChange={(e) => handleEditChange(index, 'price', e.target.value)}
                                                        size="small"
                                                        style = {{width: '100%'}}
                                                    />
                                                </Stack>
                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                    <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                                        Currency:
                                                    </Typography>
                                                    <TextField
                                                        type="text"
                                                        value={editedFields.currency}
                                                        onChange={(e) => handleEditChange(index, 'currency', e.target.value)}
                                                        size="small"
                                                        style = {{width: '100%'}}
                                                    />
                                                </Stack>

                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                    <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                                        Description:
                                                    </Typography>
                                                    <TextField
                                                        type="text"
                                                        value={editedFields.description}
                                                        onChange={(e) => handleEditChange(index, 'description', e.target.value)}
                                                        size="small"
                                                        style = {{width: '100%'}}
                                                        multiline
                                                        rows={null}
                                                    />
                                                </Stack>
                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                    <FormControlLabel
                                                        control={<Checkbox
                                                            checked={editedFields.showToUser}
                                                            onChange={() => handleEditChange(index, 'showToUser', !editedFields.showToUser)}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />}
                                                        label="Show To User"
                                                    />
                                                </Stack>
                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                    <FormControlLabel
                                                        control={<Checkbox
                                                            checked={editedFields.showDiscount}
                                                            onChange={() => handleEditChange(index, 'showDiscount', !editedFields.showDiscount)}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />}
                                                        label="Show Discount"
                                                    />
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                        <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                                            Price with discount:
                                                        </Typography>
                                                        <TextField
                                                            type="text"
                                                            value={editedFields.priceWithDiscount}
                                                            onChange={(e) => handleEditChange(index, 'priceWithDiscount', e.target.value)}
                                                            size="small"
                                                            style = {{width: '100%'}}
                                                        />
                                                    </Stack>
                                                </Stack>
                                            </Stack>

                                            <Stack direction="row" spacing={2} p={2}>
                                                <Button onClick={() => {
                                                    console.log('date.id from bnt', date.id)
                                                    handleEdit(date.id);
                                                    setShowEditCardModal(false);
                                                }} variant="contained">Save</Button>
                                                <Button onClick={() => {
                                                    deleteDate(date.id);
                                                    setShowEditCardModal(false);
                                                }} variant="outlined">Delete</Button>
                                            </Stack>
                                        </div>
                                    )
                                }

                                <div className='adminCard'>
                                    <Stack direction="row" spacing={1}>
                                        <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                            Name:
                                        </Typography>
                                        <Typography gutterBottom variant="body2" component="p">
                                            {date.name}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={1}>
                                        <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                            Date:
                                        </Typography>
                                        <Typography gutterBottom variant="body2" component="p">
                                            {date.date}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={1}>
                                        <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                            Location:
                                        </Typography>
                                        <Typography gutterBottom variant="body2" component="p">
                                            {date.location}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={1}>
                                        <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                            Adress:
                                        </Typography>
                                        <Typography gutterBottom variant="body2" component="p">
                                            {date.adress}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={1}>
                                        <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                            Man Amount:
                                        </Typography>
                                        <Typography gutterBottom variant="body2" component="p">
                                            {date.manAmount}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={1}>
                                        <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                            Man Bought:
                                        </Typography>
                                        <Typography gutterBottom variant="body2" component="p">
                                            {date.manAmountBought}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={1}>
                                        <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                            Woman Amount:
                                        </Typography>
                                        <Typography gutterBottom variant="body2" component="p">
                                            {date.womanAmount}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={1}>
                                        <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                            Woman Amount Bought:
                                        </Typography>
                                        <Typography gutterBottom variant="body2" component="p">
                                            {date.womanAmountBought}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={1}>
                                        <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                            Age:
                                        </Typography>
                                        <Typography gutterBottom variant="body2" component="p">
                                            {date.age}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={1}>
                                        <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                            Time:
                                        </Typography>
                                        <Typography gutterBottom variant="body2" component="p">
                                            {date.time}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={1}>
                                        <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                            Animator:
                                        </Typography>
                                        <Typography gutterBottom variant="body2" component="p">
                                            {date.animator}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={1}>
                                        <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                            Price: {date.price} {date.currency}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={1}>
                                        <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                            Show to user:
                                        </Typography>
                                        <Typography variant="body2" component="div" sx={{ overflowX: 'auto' }}>
                                            {date.showToUser}
                                            {JSON.stringify(date.showToUser)}
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={1}>
                                        <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                            Show discount:
                                        </Typography>
                                        <Typography variant="body2" component="div" sx={{ overflowX: 'auto' }}>
                                            {date.showDiscount}
                                            {JSON.stringify(date.showDiscount)}
                                        </Typography>

                                    </Stack>
                                    <Stack direction="row" spacing={1}>
                                        <Typography sx={{ fontWeight: 'bold' }} gutterBottom variant="body2" component="p">
                                            Price with discount:
                                        </Typography>
                                        <Typography variant="body2" component="div" sx={{ overflowX: 'auto' }}>
                                            {date.priceWithDiscount}
                                        </Typography>
                                    </Stack>
                                    <div className='adminCardActionButtons'>
                                        <button onClick={() => handleOpenModalEdit(date.id)} className='adminCardActionButton'>Edit</button>
                                        <Link
                                            to={`/admin/${date.id}`} className='adminCardActionButton'>View all info</Link>
                                        <button onClick={() => deleteDate(date.id)} className='adminCardActionButton'>Delete</button>
                                    </div>
                                </div>


                            </div>
                        ))}
                    </div>
                </>
            ) : (
                <div className='adminLogin'>
                    <h1>Admin</h1>
                    <input type="text" placeholder='email' value={adminEmail} onChange={(e)=> setAdminEmail(e.target.value)}/>
                    <input type="text" placeholder='password' value={adminPass} onChange={(e)=> setAdminPass(e.target.value)}/>
                    <button onClick={handleLogin}>login</button>
                </div>
            )
             }


        </div>
    );
}

export default AdminPage;
import React from 'react';
import logo from "../../LOGO_header.png";
import logoText from "../../logo_text.png";
import {Link} from "react-router-dom";
import facebook from "../../facebook.png";
import instagram from "../../instagram.png";
import './../../pages/MainPage/MainPage.css'

function Header() {
    return (
        <div className='header'>
            <div className='headerContent'>
                <Link to="/" className='logoWrapper'>
                    <div className='logo'>
                        <img src={logo} alt="" className='logoImg'/>
                    </div>
                    <div className='logoTextImgWrapper'>
                        <img src={logoText} alt="" className=''/>
                    </div>
                </Link>
                <ul className='navList'>
                    <li className='navItem'>
                        <Link to="/" className='navItemLink'>Wydarzenia</Link>
                    </li>
                    <li className='navItem navItemMobile'>
                        <Link to="/contacts" className='navItemLink'>Kontakt</Link>
                    </li>
                    <li className='navItem navItemMobile'>
                        <Link to="/regulamin" className='navItemLink'>Regulamin</Link>
                    </li>
                    <li className='navItemMobile'>
                        <Link to="https://www.instagram.com/realdate.dating?igsh=YmM2ZzRtYzFieWVo" target='_blank' className='navItemLink'>
                            <div className='socialNetworkImgWrapper'>
                                <img src={facebook} alt="" className='socialNetworkImg'/>
                            </div>
                        </Link>
                    </li>
                    <li className='navItemMobile'>
                        <Link to="https://www.instagram.com/realdate.dating?igsh=YmM2ZzRtYzFieWVo" target='_blank' className='navItemLink'>
                            <div className='socialNetworkImgWrapper'>
                                <img src={instagram} alt="" className='socialNetworkImg'/>
                            </div>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Header;
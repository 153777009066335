import React from 'react';
import './DatePage.css';
import {useParams} from "react-router-dom";
import {useState,useEffect} from "react";
import {doc, getDoc } from "firebase/firestore/lite";
import db from "../../firebase";
import {useNavigate} from "react-router-dom";
import Radio from '@mui/material/Radio';
import {RingLoader} from "react-spinners";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import './../GeneralStyle.css'
import ReactMarkdown from 'react-markdown'
function DatePage() {
    const { dateId } = useParams();
    const [dateInfo, setDateInfo] = useState(null);
    const [selectedSex, setSelectedSex] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const navigate = useNavigate();

    const fetchDateInfo = async () => {
        try {
            const dateDoc = await getDoc(doc(db, "dates", dateId));
            if (dateDoc.exists()) {
                setDateInfo({ id: dateDoc.id, ...dateDoc.data() });
            } else {
                console.log("No such document!");
            }
        } catch (error) {
            console.error("Error fetching date info:", error);
        }
    };
    useEffect(() => {
        fetchDateInfo();
    }, [dateId]);

    const onChangeSexInput = (sex) => {
        setSelectedSex(sex);
        const manAmount = parseInt(dateInfo.manAmount);
        const manAmountBought = parseInt(dateInfo.manAmountBought);
        const womanAmount = parseInt(dateInfo.womanAmount);
        const womanAmountBought = parseInt(dateInfo.womanAmountBought);

        if (sex === 'mężczyzna' && manAmount !== manAmountBought) {
            setButtonDisabled(false);

        } else if (sex === 'kobieta' && womanAmount !== womanAmountBought) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }

    const buyDate = async() => {
        // console.log('buy date');
        if(dateInfo.showDiscount) {
            navigate(`/date/${dateId}/pay`, { state: { sex: selectedSex, amount: dateInfo.priceWithDiscount }});
        } else {
            navigate(`/date/${dateId}/pay`, { state: { sex: selectedSex, amount: dateInfo.price }});
        }
    };

    return (
        <div className='container'>
            <Header />
            <div>
                {dateInfo ? (
                    <div className='wholeDateWrapper'>
                        <div className='wholeDate'>
                            <div className='wholeDateLeft'>
                                <div className='mainDateImgWrapper'>
                                    <img src={dateInfo.imgLink} alt="" className='mainDateImg'/>
                                </div>
                            </div>
                            <div className='wholeDateRight'>
                                <div className='wholeDateRightContent'>
                                    <p className='wholeDateName'> {dateInfo.name}</p>
                                    <p className='wholeDateLocation'> {dateInfo.location}</p>
                                    <button onClick={buyDate} disabled={buttonDisabled} className='btnBuyDateNow'>Kup bilet</button>
                                    {buttonDisabled && selectedSex !== '' && <p style={{marginBottom: '20px', color: 'red'}}>Brak miejsc</p>}
                                    <div className='wholeDateSexWrapper'>
                                        <div className='sex'>
                                            <svg width="24" height="58" viewBox="0 0 168 449" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M84 74.6C104.6 74.7 121.3 58 121.3 37.3C121.3 16.7 104.6 0 84 0C63.4 0 46.7 16.7 46.7 37.3C46.7 57.9 63.4 74.6 84 74.6ZM121.4 83H46.6C18.4 83 0 107.8 0 131.4V245C0 267 31 267 31 245V140H37V425.6C37 456 79 455 80 425.6V261H88V425.7C89.7 456.9 131 453.9 131 425.6V140H136V245C136 267 168 267 168 245V131.4C168 107.9 149.5 83 121.4 83Z" fill="#34A853"/>
                                            </svg>
                                            <p className='maleAmount'>{dateInfo.manAmountBought}/{dateInfo.manAmount}</p>
                                            <Radio
                                                color="success"
                                                sx={{
                                                    color: '#34A853',
                                                    '&.Mui-checked': {
                                                        color: '#34A853',
                                                    },
                                                }}
                                                checked={selectedSex === 'mężczyzna'}
                                                onChange={() => onChangeSexInput('mężczyzna')}
                                                value="a"
                                                name="radio-buttons"
                                                inputProps={{ 'aria-label': 'A' }}
                                            />
                                        </div>
                                        <div className='sex'>
                                            <svg width="24" height="58" viewBox="0 0 193 450" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M30.4002 117.6L1.00023 221.9C-5.29977 244.7 21.7002 253.6 28.3002 232.2L54.6002 136H62.0002L16.8002 305H59.0002V432C59.0002 455 91.0002 455 91.0002 432V305H101V432C101 455 132 455 132 432V305H175.4L129.2 136H137.6L163.9 232.2C170.4 254.1 197.2 244.7 191.2 222L161.8 117.6C157.8 105.8 143.6 85.0004 119.8 84.0004H72.5002C47.9002 85.0004 33.8002 105.6 30.4002 117.6ZM132.6 38.2004C132.6 17.6004 116.2 0.900391 96.0002 0.900391C75.8002 0.900391 59.4002 17.6004 59.4002 38.2004C59.4002 58.8004 75.8002 75.5004 96.0002 75.5004C116.2 75.5004 132.6 58.8004 132.6 38.2004Z" fill="#fe01b1"/>
                                            </svg>
                                            <h1 className='femaleAmount'>{dateInfo.womanAmountBought}/{dateInfo.womanAmount}</h1>
                                            <Radio
                                                sx={{
                                                    color: '#fe01b1',
                                                    '&.Mui-checked': {
                                                        color: '#fe01b1',
                                                    },
                                                }}
                                                checked={selectedSex === 'kobieta'}
                                                onChange={() => onChangeSexInput('kobieta')}
                                                value="a"
                                                name="radio-buttons"
                                                inputProps={{ 'aria-label': 'A' }}
                                            />
                                        </div>
                                    </div>
                                    <div className='wholeDateInfoWrapper'>
                                        <div className='wholeDateInfoLeft'>
                                            <p className='wholeDateInfoLabel'>Wiek</p>
                                            <p className='wholeDateInfoValue'>{dateInfo.age}</p>
                                        </div>
                                        <div className='wholeDateInfoRight'>
                                            <p className='wholeDateInfoLabel'>Cena</p>
                                            {
                                                dateInfo.showDiscount ? (
                                                    <p className='infoValue'><span className='stroked'>{dateInfo.price}{dateInfo.currency}</span><span>&nbsp;&nbsp;&nbsp;</span><b>{dateInfo.priceWithDiscount}{dateInfo.currency}</b></p>
                                                ) : (
                                                    <p className='infoValue'><b>{dateInfo.price} {dateInfo.currency}</b></p>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className='wholeDateInfoWrapper'>
                                        <div className='wholeDateInfoLeft'>
                                            <p className='wholeDateInfoLabel'>Data</p>
                                            <p className='wholeDateInfoValue'>{dateInfo.date}</p>
                                        </div>
                                        <div className='wholeDateInfoRight'>
                                            <p className='wholeDateInfoLabel'>Godzina</p>
                                            <p className='wholeDateInfoValue'>{dateInfo.time}</p>
                                        </div>
                                    </div>
                                    <div className='wholeDateInfoWrapper'>
                                        <div className='wholeDateInfoWrapperOneRow'>
                                            <p className='wholeDateInfoLabel'>Miasto</p>
                                            <p className='wholeDateInfoValue red'>{dateInfo.location}</p>
                                        </div>
                                    </div>
                                    <div className='wholeDateInfoWrapper'>
                                        <div className='wholeDateInfoWrapperOneRow'>
                                            <p className='wholeDateInfoLabel'>Adres</p>
                                            <p className='wholeDateInfoValue'>{dateInfo.adress}</p>
                                        </div>
                                    </div>
                                    <div className='wholeDateInfoWrapper wholeDateInfoWrapperDescription'>
                                        <div className='wholeDateInfoWrapperOneRow'>
                                            <p className='wholeDateInfoLabel'>Opis</p>
                                            <div dangerouslySetInnerHTML={{__html: dateInfo.description}} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='spinnerWrapper'>
                        <RingLoader color="#e0566b" />
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
}

export default DatePage;
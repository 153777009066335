import './SuccessPurchase.css'

import React from 'react';
import {useNavigate} from "react-router-dom";

function SuccessPurchase() {
    const navigate = useNavigate();

    return (
        <div className='successPurchaseContainer'>
            <div className='successPurchaseContainerText'>
                <p className='successPurchaseContainerTextContent'>Kupiłeś bilet, sprawdź swojego maila</p>
                <button onClick={() => navigate('/')}  className='successPurchaseBtn'>Powrót do strony głównej</button>
            </div>

        </div>
    );
}

export default SuccessPurchase;
import React from 'react';
import './AdminDatePage.css';
import {useParams} from "react-router-dom";
import {useState,useEffect} from "react";
import {doc, getDoc } from "firebase/firestore/lite";
import db from "../../firebase";
import {useNavigate} from "react-router-dom";

function AdminDatePage(props) {
    const { dateId } = useParams(); // Extract the dateId from URL parameters
    const [dateInfo, setDateInfo] = useState(null);
    const [selectedSex, setSelectedSex] = useState(''); // State to track selected sex
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const navigate = useNavigate();

    const fetchDateInfo = async () => {
        try {
            const dateDoc = await getDoc(doc(db, "dates", dateId)); // Fetch the date document based on dateId
            if (dateDoc.exists()) {
                setDateInfo({ id: dateDoc.id, ...dateDoc.data() }); // Set the date info state
            } else {
                console.log("No such document!");
            }
        } catch (error) {
            console.error("Error fetching date info:", error);
        }
    };
    useEffect(() => {
        fetchDateInfo();
    }, [dateId]);

    const onChangeSexInput = (sex) => {
        setSelectedSex(sex);
        const manAmount = parseInt(dateInfo.manAmount);
        const womanAmount = parseInt(dateInfo.womanAmount);

        if (sex === 'mężczyzna' && manAmount > 0) {
            console.log('Buy Mężczyzna');
            setButtonDisabled(false);

        } else if (sex === 'kobieta' && womanAmount > 0) {
            console.log('Buy kobieta');
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }

    const buyDate = async() => {
        console.log('buy date')
        navigate(`/date/${dateId}/pay`, { state: { sex: selectedSex }});
    };

    return (
        <div>
            <div>
                {dateInfo ? (
                    <div className='adminDateWholeContainer'>
                        <div className='adminDateWholeCard'>
                            <p><b>Name:</b> {dateInfo.name}</p>
                            <p><b>Date:</b> {dateInfo.date}</p>
                            <p><b>Location:</b> {dateInfo.location}</p>
                            <p><b>Adress:</b> {dateInfo.adress}</p>
                            <p><b>Man Amount:</b> {dateInfo.manAmount}</p>
                            <p><b>Man Amount Bought:</b> {dateInfo.manAmountBought}</p>
                            <p><b>Woman Amount:</b> {dateInfo.womanAmount}</p>
                            <p><b>Woman Amount Bought:</b> {dateInfo.womanAmountBought}</p>
                            <p><b>Age:</b> {dateInfo.age}</p>
                            <p><b>Time:</b> {dateInfo.time}</p>
                            <p><b>Price:</b> {dateInfo.price}{dateInfo.currency}</p>
                            <p><b>Description:</b> {dateInfo.description}</p>
                            <p><b>Show discount:</b> {dateInfo.showDiscount}</p>
                            <p><b>Price with discount:</b> {dateInfo.priceWithDiscount}</p>
                            <p><b>Show to user:</b> {dateInfo.showToUser}</p>
                            <p><b>Unique Date Id which is shown for user:</b> {dateInfo.uniqueDateId}</p>
                        </div>

                        <h3><b>Users that have bought this date</b></h3>
                        <div style={{display: 'flex', justifyContent: 'spaceBetween'}}>
                            {
                                dateInfo.users.map((user) => {
                                    return (
                                        <div style={{border: '2px solid red', width: '300px', display: 'block', marginRight: '10px'}}>
                                            <p>First Name: {user.firstName}</p>
                                            <p>Last name: {user.lastName}</p>
                                            <p>Email: {user.email}</p>
                                            <p>Sex: {user.sex}</p>
                                            <p>City: {user.city}</p>
                                            <p>Country: {user.country}</p>
                                            <p>Phone: {user.phone}</p>
                                            <p>Age: {user.birthYear}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                ) : (
                    <p>Loading...</p>
                )}
            </div>
        </div>
    );
}

export default AdminDatePage;
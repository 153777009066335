import React from 'react';
import StripeContainer from "../../components/PaymentForm/StripeContainer";
import './PayPage.css';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import './../GeneralStyle.css'

function PayPage() {
    return (
        <div className='container'>
            <Header />
            <div className='payContainer'>
                <StripeContainer />
            </div>
            <Footer />
        </div>
    );
}

export default PayPage;
import React from 'react';
import './PrivacyPolicy.css';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import './../GeneralStyle.css'

function PrivacyPolicy() {
    return (
        <div className='container'>
            <Header />
            <div className='privacyPolicyContainer'>
                <h1 className='rulesTitle'>Polityka Prywatności</h1>
                <ol className='rulesList'>
                    <li className='rulesItem'>Administratorem danych osobowych jest osoba fizyczna Paweł Dzik prowadzący nierejestrowaną działalność gospodarczą pod nazwą real-date, z siedzibą w Warszawie.</li>
                    <li className='rulesItem'>Przetwarzanie danych osobowych odbywa się na podstawie art. 6 RODO oraz w celach koniecznych do wykonania zamówionej usługi.</li>
                    <li className='rulesItem'>Przetwarzanie i przechowywanie danych osobowych obowiązuje przez okres działalność strony internetowej <a href='www.real-date.pl'>www.real-date.pl</a> lub do odwołania tejże zgody.</li>
                    <li className='rulesItem'>Serwis zbiera informacje dobrowolnie podane przez użytkownika.</li>
                    <li className='rulesItem'>Dane osobowe pozostawione w serwisie nie zostaną sprzedane ani udostępnione osobom trzecim, zgodnie z przepisami Ustawy o ochronie danych osobowych.</li>
                    <li className='rulesItem'>Do danych zawartych w formularzu przysługuje wgląd osobie fizycznej, która je tam umieściła. Osoba ta ma również praw do modyfikacji i zaprzestania przetwarzania swoich danych w dowolnym momencie.</li>
                </ol>
            </div>
            <Footer />
        </div>
    );
}

export default PrivacyPolicy;
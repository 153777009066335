import React, {useEffect, useState} from 'react';
import {collection, getDocs} from "firebase/firestore/lite";
import db from "../../firebase";
import {Link} from "react-router-dom";
import './MainPage.css'
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import './../GeneralStyle.css'
import {RingLoader} from "react-spinners";

function MainPage() {
    const [allDates, setAllDates] = useState([]);

    const [isLoadingDates, setIsLoadingDates] = useState(true);
    useEffect(() => {
        const fetchDates = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "dates"));
                const dates = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                const filteredDates = dates.filter((date) => date.showToUser);
                setAllDates(filteredDates);
                setIsLoadingDates(false);
            } catch (error) {
                console.error("Error fetching dates:", error);
            }
        };

        fetchDates();
    }, []);

    return (
        <div className='container'>
            <Header />
            <div className='mainBlock'>
                <div className='mainBlockContent'>
                    <p className='mainBlockTitle'>Przestań swipować<br/>Zacznij randkować</p>
                    <p className='mainBlockSubtitle'>Speed dating to idealna opcja dla zabieganych warszawiaków, którzy chcą znaleźć miłość w szybkim tempie!<br/> W ciągu jednego wieczoru poznasz kilkanaście osób, gotowych na prawdziwe spotkania twarzą w twarz.</p>
                </div>
            </div>
            <div className='cardsWrapper'>
                <div className='cards'>

                    {
                        isLoadingDates ? (
                            <div className='spinnerWrapper'>
                                <RingLoader color="#e0566b" />
                            </div>
                        ) : (
                            allDates.map(date => {
                                return (
                                    <div className='cardWrapper'>
                                        <Link className='card'
                                              to={`/date/${date.id}`}>
                                            <div className='cardImgNameWrapper'>
                                                <div className='cardImgWrapper'>
                                                    <img  className='cardImg' src={date.imgLink} alt=""/>
                                                </div>
                                                <div className='cardImgName'>
                                                    <p className='dateName'>{date.name}</p>
                                                    <p className='dateLocation'>{date.location}</p>
                                                </div>
                                            </div>
                                            <div className='dateInfo'>
                                                <div className='sexWrapper'>
                                                    <div className='sex border'>
                                                        <svg width="24" height="58" viewBox="0 0 168 449" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M84 74.6C104.6 74.7 121.3 58 121.3 37.3C121.3 16.7 104.6 0 84 0C63.4 0 46.7 16.7 46.7 37.3C46.7 57.9 63.4 74.6 84 74.6ZM121.4 83H46.6C18.4 83 0 107.8 0 131.4V245C0 267 31 267 31 245V140H37V425.6C37 456 79 455 80 425.6V261H88V425.7C89.7 456.9 131 453.9 131 425.6V140H136V245C136 267 168 267 168 245V131.4C168 107.9 149.5 83 121.4 83Z" fill="#34A853"/>
                                                        </svg>
                                                        <p className='maleAmount'>{date.manAmountBought}/{date.manAmount}</p>
                                                    </div>
                                                    <div className='sex'>
                                                        <svg width="24" height="58" viewBox="0 0 193 450" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M30.4002 117.6L1.00023 221.9C-5.29977 244.7 21.7002 253.6 28.3002 232.2L54.6002 136H62.0002L16.8002 305H59.0002V432C59.0002 455 91.0002 455 91.0002 432V305H101V432C101 455 132 455 132 432V305H175.4L129.2 136H137.6L163.9 232.2C170.4 254.1 197.2 244.7 191.2 222L161.8 117.6C157.8 105.8 143.6 85.0004 119.8 84.0004H72.5002C47.9002 85.0004 33.8002 105.6 30.4002 117.6ZM132.6 38.2004C132.6 17.6004 116.2 0.900391 96.0002 0.900391C75.8002 0.900391 59.4002 17.6004 59.4002 38.2004C59.4002 58.8004 75.8002 75.5004 96.0002 75.5004C116.2 75.5004 132.6 58.8004 132.6 38.2004Z" fill="#fe01b1"/>
                                                        </svg>
                                                        <h1 className='femaleAmount'>{date.womanAmountBought}/{date.womanAmount}</h1>
                                                    </div>
                                                </div>
                                                <div className='infoWrapper'>
                                                    <div className='infoBlock border'>
                                                        <p className='infoTitle'>Data:</p>
                                                        <p className='infoValue'>{date.date}</p>
                                                    </div>
                                                    <div className='infoBlock border'>
                                                        <p className='infoTitle'>Wiek:</p>
                                                        <p className='infoValue'>{date.age}</p>
                                                    </div>
                                                    <div className='infoBlock'>
                                                        <p className='infoTitle'>Cena:</p>
                                                        {
                                                            date.showDiscount ? (
                                                                <p className='infoValue'><span className='stroked'>{date.price}{date.currency}</span><span>&nbsp;&nbsp;&nbsp;</span><b>{date.priceWithDiscount}{date.currency}</b></p>
                                                            ) : (
                                                                <p className='infoValue'><b>{date.price} {date.currency}</b></p>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <Link className='card'
                                                  to={`/date/${date.id}`}>
                                                <button className='btnBook'>Kup bilet</button>
                                            </Link>
                                        </Link>
                                    </div>
                                )
                            })
                        )
                    }
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default MainPage;
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import React from "react"
import PaymentForm from "./PaymentForm"
import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {RingLoader} from "react-spinners";

const PUBLIC_KEY = "pk_live_51PGLAvAh5IVwJ5yhabjKvEFEkPfpnsJ03xl2wzeu2BVij54FyppqvVzQCWrpA67CZkMFbCp685Qyw4GchGcYwoZN000jDzR07k";

const stripeTestPromise = loadStripe(PUBLIC_KEY)

export default function StripeContainer() {
    const [clientSecret, setClientSecret] = useState("");
    const { state } = useLocation();
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        // console.log('state',state)
        // console.log('state',state.amount)
        const raw = JSON.stringify({
            "amount": state.amount*100,
        });
        // console.log('raw',raw)


        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };
        // fetch("http://localhost:4000/payment", requestOptions)
        fetch("https://realdate.onrender.com/payment", requestOptions)
            .then((response) => response.text())
            .then((result) => {
                console.log('result', JSON.parse(result));
                let data = JSON.parse(result);
                setClientSecret(data.clientSecret);
                setLoading(false);
            })
            .catch((error) => console.error(error));
    }, []);
    const appearance = {
        theme: 'stripe',
    };
    const options = {
        appearance,
        clientSecret,
        locale: 'pl'
    };

    return (
        <div>
            {
                loading ? (
                    <div className='spinnerWrapper'>
                        <h2 className='spinnerWrapperText'>Za chwilę przejdziesz do formularza i opłaty</h2>
                        <RingLoader color="#e0566b" />
                    </div>
                    ) : (
                    clientSecret && (
                        <Elements options={options} stripe={stripeTestPromise}>
                            <PaymentForm/>
                        </Elements>
                    )
                )

            }

        </div>
    )
}

import {doc, updateDoc} from "firebase/firestore/lite";
import db from "../firebase";

export const updateDocument = async(dateId, updatedDate) => {
    try {
        await updateDoc(doc(db, "dates", dateId), updatedDate);
    } catch (error) {
        console.error("Error updating document: ", error);
        throw error;
    }
}
import React from 'react';
import './Contacts.css';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import './../GeneralStyle.css'

function Contacts(props) {
    return (
        <div className='container'>
            <Header />
            <div className='contactsWrapper'>
                <h2 className='contactsTitle'>Kontakt</h2>
                <p className='contactsSubtitle'>Skontaktuj się z nami poprzez email albo Instagram lub Faceboka, na wszystkie pytania odpowiemy najszybciej jak to możliwe</p>
                <div className='contactBlock'>
                    <p className='contactBlockTitle'>Email</p>
                    <p className='contactBlockValue'>kontakt@real-date.pl</p>
                </div>
                <div className='contactBlock'>
                    <p className='contactBlockTitle'>Adres</p>
                    <p className='contactBlockValue'>Warszawa</p>
                </div>
                <div className='contactBlock'>
                    <p className='contactBlockTitle'>Telefon</p>
                    <p className='contactBlockValue'>+48660097117</p>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Contacts;
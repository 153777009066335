import { useElements, useStripe, PaymentElement } from "@stripe/react-stripe-js"
import React, {useEffect, useState} from 'react'
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {doc, getDoc} from "firebase/firestore/lite";
import db from "../../firebase";
import {updateDocument} from "../../services/utils";
import emailjs from "@emailjs/browser";
import FloatLabel from "../FloatLabel/FloatLabel";
import './PaymentForm.css'
import Checkbox from '@mui/material/Checkbox';
import {ClipLoader, RingLoader} from "react-spinners";

export default function PaymentForm() {
    const [success, setSuccess ] = useState(false)
    const stripe = useStripe();
    const elements = useElements();
    const { dateId } = useParams();
    const [error, setError] = useState(null);
    const [dateInfo, setDateInfo] = useState(null);
    const [wrongEmail, setWrongEmail] = useState(null);
    const { state } = useLocation();
    const navigate = useNavigate();
    const [checked, setChecked] = useState(false);
    const [checkedRegulamin, setCheckedRegulamin] = useState(false);
    const handleChange = (event) => {
        setChecked(event.target.checked);
    };
    const handleChangeRegulamin = (event) => {
        setCheckedRegulamin(event.target.checked);
    };
    const [loading, setLoading] = useState(false);
    const [userInfo, setUserInfo] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        birthYear: '',
        sex: state.sex,
        city: '',
        country: '',
    })
    const fetchDateInfo = async () => {
        try {
            const dateDoc = await getDoc(doc(db, "dates", dateId));
            if (dateDoc.exists()) {
                setDateInfo({ id: dateDoc.id, ...dateDoc.data() });
            } else {
                // console.log("No such document!");
            }
        } catch (error) {
            console.error("Error fetching date info:", error);
        }
    };
    const buyDate = async () => {
        let updatedDate = {};
        if (state.sex === 'mężczyzna' ) {
            updatedDate={
                ...dateInfo,
                manAmountBought: +dateInfo.manAmountBought+1,
                users: [...dateInfo.users, userInfo]
            }
        } else if (state.sex === 'kobieta') {
            updatedDate={
                ...dateInfo,
                womanAmountBought: +dateInfo.womanAmountBought+1,
                users: [...dateInfo.users, userInfo]
            }
        }
        await updateDocument(dateId, updatedDate);
    }
    const sendEmail = async () => {
        // const serviceId = "service_bl2xqrw";
        const serviceId = "service_8ilh8yq";
        // const templateId = "template_72uhdw9";
        const templateId = "template_dg69p4y";
        try {
            setLoading(true);
            await emailjs.send(serviceId, templateId, {
                name: userInfo.firstName,
                recipient: userInfo.email,
                date: dateInfo.date,
                time: dateInfo.time,
                dateName: dateInfo.name,
                location: dateInfo.location,
                age: dateInfo.age,
                uniqueDateId: dateInfo.uniqueDateId,
                adress: dateInfo.adress,
                animator: dateInfo.animator,
            });
            navigate('/successPurchase');
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        emailjs.init("qHUuVwsUBOwH9Yfxu");
        // emailjs.init("89hp50XjuYfbb6IBM");
        fetchDateInfo();
    }, []);
    const [errors, setErrors] = useState({});
    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Płatność zakończona sukcesem!");
                    break;
                case "processing":
                    setMessage("Płatność jest przetwarzana.");
                    break;
                case "requires_payment_method":
                    setMessage("Twoja płatność się nie powiodła, spróbuj jeszcze raz.");
                    break;
                default:
                    setMessage("Coś poszło nie tak.");
                    break;
            }
        });
    }, [stripe]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!stripe || !elements) {
            return;
        }
        const validationErrors = {};
        for (const key in userInfo) {
            if (userInfo[key].trim() === '') {
                validationErrors[key] = 'To pole jest wymagane.';
            }
            window.scrollTo(0, 0)
        }
        if (checked === false) {
            validationErrors['checked'] = 'To pole jest wymagane.';
            window.scrollTo(0, 0)
        }
        if (checkedRegulamin === false) {
            validationErrors['checkedRegulamin'] = 'To pole jest wymagane.';
            window.scrollTo(0, 0)
        }
        if (wrongEmail !== '') {
            validationErrors['checkedRegulamin'] = 'To pole jest wymagane.';
            window.scrollTo(0, 0)
        }
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0 && checked && checkedRegulamin && isEmailMatched && wrongEmail === '') {
            setIsLoading(true);
            const response = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: "https://speeddating-1dc2a.web.app/successPurchase",
                },
                redirect: 'if_required'
            });

            if (response.paymentIntent && response.paymentIntent.status === "succeeded") {
                setLoading(false);
                setSuccess(true);
                buyDate();
                sendEmail();
            } else if (response.error && (response.error.type === "card_error" || response.error.type === "validation_error")) {
                setMessage(response.error.message);
            } else {
                setMessage("Nieoczekiwany błąd");
            }

            setIsLoading(false);
        }
    }
    const paymentElementOptions = {
        layout: "tabs"
    }
    const handleEmailChange = (e) => {
        const email = e.target.value;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailRegex.test(email)) {
            setWrongEmail('');

        } else {
            setWrongEmail('To nie jest adres email');
        }
        setUserInfo(prevState => ({
            ...prevState,
            email: email
        }));
    };

    const handleRepeatEmailChange = (e) => {
        const repeatEmail = e.target.value;
        setUserInfo(prevState => ({
            ...prevState,
            repeatEmail: repeatEmail,
        }));
    };
    const isEmailMatched = userInfo.email === userInfo.repeatEmail;

    return (
        <>
            {loading ? (
                    <div className='spinnerWrapper'>
                        <RingLoader color="#e0566b" />
                        <p className='spinnerWrapperText'>Płatność jest przetwarzana, prosimy czekać. Po opłacie otrzymasz wiadomość email</p>
                    </div>
                ) : (

                dateInfo !== null ? (
                    <div className='paymentInputsWrapper'>
                        <p className='dateInfoName'>{dateInfo.name}</p>
                        {
                            dateInfo.showDiscount ? (
                                <p className='dateInfoPrice'><span className='stroked'>{dateInfo.price}{dateInfo.currency}</span><span>&nbsp;&nbsp;&nbsp;</span><b>{dateInfo.priceWithDiscount}{dateInfo.currency}</b></p>
                            ) : (
                                <p className='dateInfoPrice'><b>{dateInfo.price} {dateInfo.currency}</b></p>
                            )
                        }
                        <div className='userDetails'>
                            <p className='userDetailsContent'>Twoje dane:</p>
                            <div className='twoInputsWrapper'>
                                <div className='floatLabelWrapper'>
                                    <FloatLabel label="Imię*" name="firstName" value={userInfo.firstName}>
                                        <input
                                            className='inputUserInfo'
                                            value={userInfo.firstName}
                                            onChange={(e) => setUserInfo((prevState) => {
                                                return {
                                                    ...prevState,
                                                    firstName:e.target.value
                                                }
                                            })}

                                        />
                                    </FloatLabel>
                                    {errors.firstName && <p className='errorMessageInput'>{errors.firstName}</p>}
                                </div>
                                <div className='floatLabelWrapper'>
                                    <FloatLabel label="Nazwisko*" name="lastName" value={userInfo.lastName}>
                                        <input
                                            className='inputUserInfo'
                                            value={userInfo.lastName}
                                            onChange={(e) => setUserInfo((prevState) => {
                                                return {
                                                    ...prevState,
                                                    lastName:e.target.value
                                                }
                                            })}
                                        />
                                    </FloatLabel>
                                    {errors.lastName && <p className='errorMessageInput'>{errors.lastName}</p>}
                                </div>

                            </div>
                            <div className='oneInputWrapper'>
                                <FloatLabel label="Telefon*" name="phone" value={userInfo.phone}>
                                    <input
                                        className='inputUserInfo'
                                        value={userInfo.phone}
                                        onChange={(e) => setUserInfo((prevState) => {
                                            return {
                                                ...prevState,
                                                phone:e.target.value
                                            }
                                        })}
                                    />
                                </FloatLabel>
                                {errors.phone && <p className='errorMessageInput'>{errors.phone}</p>}
                            </div>
                            <div className='twoInputsWrapper'>
                                <div className='floatLabelWrapper'>
                                    <FloatLabel label="Email*" name="email" value={userInfo.email}>
                                        <input
                                            className='inputUserInfo'
                                            value={userInfo.email}
                                            onChange={handleEmailChange}

                                        />
                                    </FloatLabel>
                                    {errors.email && <p className='errorMessageInput'>{errors.email}</p>}
                                    {wrongEmail !== '' && <p className='errorMessageInput'>{wrongEmail}</p>}

                                </div>

                                <div className='floatLabelWrapper'>
                                    <FloatLabel label="Powtórz email*" name="repeatEmail" value={userInfo.repeatEmail}>
                                        <input
                                            className='inputUserInfo'
                                            value={userInfo.repeatEmail}
                                            onChange={handleRepeatEmailChange}
                                        />
                                    </FloatLabel>
                                    {!isEmailMatched && <p className='errorEmails errorMessageInput'>Email nie pasuje</p>}
                                </div>
                            </div>
                            <div className='twoInputsWrapper'>
                                <div className='floatLabelWrapper'>
                                    <FloatLabel label="Wiek*" name="birthYear" value={userInfo.birthYear}>
                                        <input
                                            className='inputUserInfo'
                                            value={userInfo.birthYear}
                                            onChange={(e) => setUserInfo((prevState) => {
                                                return {
                                                    ...prevState,
                                                    birthYear:e.target.value
                                                }
                                            })}

                                        />
                                    </FloatLabel>
                                    {errors.birthYear && <p className='errorMessageInput'>{errors.birthYear}</p>}
                                </div>
                                <div className='floatLabelWrapper'>
                                    <FloatLabel label="Płeć*" name="sex" value={userInfo.sex}>
                                        <input
                                            className='inputUserInfo'
                                            value={userInfo.sex}
                                            disabled
                                        />
                                    </FloatLabel>
                                </div>
                            </div>
                            <div className='twoInputsWrapper'>
                                <div className='floatLabelWrapper'>
                                    <FloatLabel label="Miasto*" name="city" value={userInfo.city}>
                                        <input
                                            className='inputUserInfo'
                                            value={userInfo.city}
                                            onChange={(e) => setUserInfo((prevState) => {
                                                return {
                                                    ...prevState,
                                                    city:e.target.value
                                                }
                                            })}
                                        />
                                    </FloatLabel>
                                    {errors.city && <p className='errorMessageInput'>{errors.city}</p>}
                                </div>
                                <div className='floatLabelWrapper'>
                                    <FloatLabel label="Kraj*" name="country" value={userInfo.country}>
                                        <input
                                            className='inputUserInfo'
                                            value={userInfo.country}
                                            onChange={(e) => setUserInfo((prevState) => {
                                                return {
                                                    ...prevState,
                                                    country:e.target.value
                                                }
                                            })}
                                        />
                                    </FloatLabel>
                                    {errors.country && <p className='errorMessageInput'>{errors.country}</p>}
                                </div>
                            </div>
                            <div className='agreeBlock'>
                                <Checkbox
                                    checked={checkedRegulamin}
                                    onChange={handleChangeRegulamin}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    sx={{
                                        color: 'red',
                                        '&.Mui-checked': {
                                            color: 'red',
                                        },
                                        padding: '0px'
                                    }}
                                />
                                <span className='agreeBlockText'>Oświadczam, iż zapoznałem się z <Link to='/regulamin' target="_blank">Regulaminem</Link>*</span>
                            </div>
                            {errors.checkedRegulamin && <p className='errorMessageInput'>{errors.checkedRegulamin}</p>}
                            <div className='agreeBlock'>
                                <Checkbox
                                    checked={checked}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    sx={{
                                        color: 'red',
                                        '&.Mui-checked': {
                                            color: 'red',
                                        },
                                        padding: '0px'
                                    }}
                                />
                                <span className='agreeBlockText'>Oświadczam, iż zapoznałem się z <Link to='/privacyPolicy' target="_blank">Polityką prywatności</Link>*</span>
                            </div>
                            {errors.checked && <p className='errorMessageInput'>{errors.checked}</p>}
                        </div>

                        <div className='userDetails'>

                            <p className='userDetailsContent'>Płatność:</p>
                                <form onSubmit={handleSubmit}>
                                    <PaymentElement id="payment-element" options={paymentElementOptions} />
                                    <div className='btnPayWrapper'>
                                         <button className='btnPay' disabled={isLoading || !stripe || !elements} id="submit">
                                            <span id="button-text">
                                              {isLoading ? (
                                                  <div className="spinner" id="spinner">
                                                    <ClipLoader color="white" />
                                                    </div>
                                              ) : "Kupuję i płacę"}
                                            </span>
                                        </button>
                                    </div>
                                    {message && <div  style={{ color: 'red', textAlign: 'center' }}>{message}</div>}
                                </form>
                        </div>
                    </div>
                ) : (
                    <div className='spinnerWrapper'>
                        <RingLoader color="#e0566b" />
                    </div>
                )

            ) }
        </>
    )
}

import React from 'react';
import './Regulamin.css';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import './../GeneralStyle.css'

function Regulamin() {
    return (
        <div className='container'>
            <Header />
            <div className='privacyPolicyContainer'>
                <h1 className='rulesTitle'>Regulamin</h1>
                <ol className='rulesList'>
                    <li className='rulesItem'>Organizatorem wydarzenia jest Paweł Dzik (osoba fizyczna)</li>
                    <li className='rulesItem'>W celu rejestracji na wydarzenie należy wypełnić formularz zgłoszeniowy na stronie <a href='www.real-date.pl'>www.real-date.pl</a> oraz wymagane jest uiszczenie jednorazowej opłaty, która zależy od wybranego wydarzenia, za pomocą platformy Stripe. Opłata może zostać uiszczona za pomocą karty bankowej lub BLIK.</li>
                    <li className='rulesItem'>W celu rejestracji na wydarzenie osoba chcąca w nim uczestniczyć zobowiązana jest do podania imienia, nazwiska, płci, wieku, numeru telefonu oraz adresu email. Dane te są niezbędne do prawidłowego przebiegu spotkania oraz jako dane poufne nie są rozpowszechniane ani udostępniane innym serwisom.</li>
                    <li className='rulesItem'>W trakcie wydarzenia osoby wyrażają chęć dalszego kontaktu z wybranymi uczestnikami. Wybrane dane kontaktowe przekazywane są przez organizatora tylko osobom, które wzajemnie wybrały siebie w trakcie spotkania, jako osobę z kim chcą przedłużyć kontakt. Dane przekazywane przez organizatora to imię, nazwisko oraz numer telefonu osoby, z którym uczestnik wzajemnie się wybrały.</li>
                    <li className='rulesItem'>Uczestnik danego wydarzenia musi spełniać jego kryteria tj. wiek i/lub inne kryteria opisane na stronie danego wydarzenia. Jeśli kryteria nie zostaną spełnione organizator ma prawo odmówić uczestnictwa w wydarzeniu, nawet jeżeli osoba opłaciła wcześniej swój wstęp.</li>
                    <li className='rulesItem'>Organizator ma prawo odmówić uczestnictwa w wydarzeniu jeżeli zachowanie osoby jest nieadekwatne lub nie dostosowuje się ona do reguł spotkania.</li>
                    <li className='rulesItem'> Uczestnicy nie powinni samodzielnie przekazywać swoich danych współuczestnikom (wyjątkiem jest imię).</li>
                    <li className='rulesItem'>W wydarzeniach mogą brać udział wyłącznie osoby pełnoletnie o statusie singla.</li>
                    <li className='rulesItem'>Rezygnacja z wydarzenia oraz zwrot środków możliwy jest nie później niż 48 godziny przed wydarzeniem. Jeżeli rezygnacja wpłynęła mniej niż 48 godzin przed wydarzeniem organizator zastrzega sobie prawo do niezwrócenia opłaty.</li>
                    <li className='rulesItem'>Organizator zastrzega sobie prawo do odwołania wydarzenie bez podawania przyczyny po wcześniejszym poinformowaniu o tym uczestników. W przypadku odwołania wydarzenia przez organizatora opłaty za wstęp zostaną zwrócone na wskazany przez uczestnika numer konta w ciągu 7 dni roboczych.</li>
                    <li className='rulesItem'>Organizator zastrzega, że liczba osób na wydarzeniu nie musi być taka jaka była podana na stronie.</li>
                    <li className='rulesItem'>Dane kontaktowe do organizatora dostępny są na stronie <a href='www.real-date.pl'>www.real-date.pl</a> w zakładce Kontakt</li>
                    <li className='rulesItem'>Reklamacja należy zgłaszać drogą mailową na adres kontakt@real-date.pl. Organizator ma 14 dni roboczych na jej rozpatrzenie.</li>
                </ol>
            </div>
            <Footer />
        </div>
    );
}

export default Regulamin;
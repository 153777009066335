import React, {useEffect, useState} from "react";
import "./App.css";
import db from "./firebase";
import {collection, getDocs, addDoc, doc, updateDoc} from "firebase/firestore/lite";
import { Routes, Route } from 'react-router-dom';
import AdminPage from "./pages/AdminPage/AdminPage";
import MainPage from "./pages/MainPage/MainPage";
import DatePage from "./pages/DatePage/DatePage";
import PayPage from "./pages/PayPage/PayPage";
import AdminDatePage from "./pages/AdminDatePage/AdminDatePage";
import SuccessPurchase from "./pages/SuccessPurchase/SuccessPurchase";
import Regulamin from "./pages/Regulamin/Regulamin";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import Contacts from "./pages/Contacts/Contacts";

function App() {

  return (
      <div className="App">
          <Routes>
              <Route path="/" element={<MainPage />} />
              <Route path="/admin" element={<AdminPage />} />
              <Route path="/regulamin" element={<Regulamin />} />
              <Route path="/contacts" element={<Contacts />} />
              <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
              <Route path="/admin/:dateId" element={<AdminDatePage />} />
              {/*<Route path="/main" element={<MainPage />} />*/}
              <Route path="/successPurchase" element={<SuccessPurchase />} />
              <Route path="/date/:dateId" element={<DatePage />} />
              <Route path="/date/:dateId/pay" element={<PayPage />} />
          </Routes>

      </div>
  );
}

export default App;

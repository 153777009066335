// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore/lite';
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDg4px_Ov3t10L7z9DuWA3RJSJfwZaxlRY",
    authDomain: "realdate-a78f0.firebaseapp.com",
    projectId: "realdate-a78f0",
    storageBucket: "realdate-a78f0.appspot.com",
    messagingSenderId: "878423984183",
    appId: "1:878423984183:web:620677e3683bdfa18cd7b2"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
const db = getFirestore(app);
export default db;
